@keyframes RingProgress {
    from {
        stroke-dasharray: 0 999;
    }
}

.innerActivityRing {
    transform-origin: 50%;
    transform: rotate(-90deg);
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    animation: RingProgress forwards;
}

.innerActivityRingBackground {
    transform-origin: 50%;
    transform: rotate(-90deg);
    fill: none;
}

