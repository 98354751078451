.medal{
	display: flex;
    z-index:1;
}

.medal-icon-ribbon-container{
	position:relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height:24px;
	width:24px;
    z-index:2;
}

.medal-icon-ribbon-container:before{
	content:'';
	z-index:-1;
	position:absolute;
	width:50%;
	background-color:#B22B32;
	height:1.2em;
    bottom:50%;
}

.medal-icon{
	position:relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height:24px;
	width:24px;
	border-radius:100%;
	border:2px solid transparent;
	text-align: center;
	color:#FFF;
    font-size: 1em;
    z-index:2;
}

.gold .medal-icon {
	border-color:#FEC223;
	background: linear-gradient(135deg, #E7B120 0%,#E7B120 50%,#FEC223 50%,#FEC223 100%);
}

.silver .medal-icon {
	border-color:#9FA6AC;
	background: linear-gradient(135deg, #91979D 0%,#91979D 50%,#9FA6AC 50%,#9FA6AC 100%);
}

.bronze .medal-icon {
	border-color:#BA765F;
	background: linear-gradient(135deg, #C0826D 0%,#C0826D 50%,#BA765F 50%,#BA765F 100%);
}

.small-text span {
    font-size: 0.8em;
}

.small-text .medal-icon-ribbon-container:before {
    height: 1.3em;
}

.small-text .medal-icon {
    bottom: 10%;
}